import { Navigate, Route, Routes } from 'react-router-dom';
import { SuperAdminAuthenticatedPath } from '@shared/constants/routes.constants';
import { CompaniesPage } from '@pages/SuperAdmin/CompaniesPage';
import { FullLayout } from '@shared/layouts';
import { MonitorConversationPage } from '@pages/SuperAdmin/MonitorConversationPage';
import { HomePage } from '@pages/SuperAdmin/HomePage';
import { MonitoringTokensPage } from '@pages/SuperAdmin/MonitoringTokensPage';

export const AuthorizedSuperAdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullLayout />}>
        <Route index element={<Navigate to="/super-admin/home" replace />} />
        <Route path="/super-admin/home" element={<HomePage />} />
        <Route path={SuperAdminAuthenticatedPath.CREATE_COMPANY} element={<CompaniesPage />} />
        <Route
          path={SuperAdminAuthenticatedPath.MONITOR_TOKENS}
          element={<MonitoringTokensPage />}
        />
        <Route
          path={SuperAdminAuthenticatedPath.MONITOR_CONVERSATION}
          element={<MonitorConversationPage />}
        />
        <Route path="*" element={<Navigate to="/super-admin/home" replace />} />
      </Route>
    </Routes>
  );
};

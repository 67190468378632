import { Link, useLocation } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { styled } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { ReactComponent as LogoIcon } from '@shared/assets/images/logos/title.svg';

import { RootState, useAppSelector } from '@shared/store';
import Spinner from '@shared/components/spinner/Spinner';
import { COMPANIES_BRANDING } from '@shared/constants/branding';

const Logo = (): JSX.Element => {
  const customizer = useAppSelector((state: RootState) => state.customizer);

  const { pathname } = useLocation();
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));
  const isSuperAdminPage = pathWithoutLastPart.includes('super-admin');

  const authenticatedUserCompany = useAppSelector(
    (state: RootState) => state.auth.authenticatedUserCompany,
  );

  const isLoading = useAppSelector((state: RootState) => state.auth.isLoading);

  const LinkStyled = styled(Link)(() => ({
    height: customizer.TopbarHeight,
    width: customizer.isCollapse ? '40px' : '180px',
    overflow: 'hidden',
    display: 'block',
  }));

  const commonLogoStyles = { height: 50, width: 200 };

  const LogoIcon = authenticatedUserCompany?.logo;

  if (isLoading || (!LogoIcon && !isSuperAdminPage)) return <Spinner />;

  const RenderedLogoIcon = LogoIcon ? (
    <LogoIcon style={commonLogoStyles} />
  ) : (
    <COMPANIES_BRANDING.default.logo />
  );

  if (customizer.activeDir === 'ltr') {
    return (
      <LinkStyled
        to="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}>
        {RenderedLogoIcon}
      </LinkStyled>
    );
  }

  return (
    <LinkStyled
      to="/"
      style={{
        display: 'flex',
        alignItems: 'center',
      }}>
      {RenderedLogoIcon}
    </LinkStyled>
  );
};

export default Logo;

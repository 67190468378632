import { ReactComponent as CallAgentLogo } from '@shared/assets/images/logos/title.svg';
import { ReactComponent as EtisalatLogo } from '@shared/assets/images/logos/etisalat-logo.svg';
import { ReactComponent as AlkhaircapitalLogo } from '@shared/assets/images/logos/alkhaircapital-logo.svg';

export const COMPANIES_BRANDING = {
  etisalat: {
    title: 'Etisalat',
    logo: EtisalatLogo,
  },
  alkhair_capital: {
    title: 'Alkhair Capital',
    logo: AlkhaircapitalLogo,
  },
  default: {
    title: 'Call Agent',
    logo: CallAgentLogo,
  },
};

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';
import { MonitorConversationList } from './MonitorConversationList';
import { RecipientConversationDto } from '@shared/services/apiService/apiService';

interface MonitorCampaignProps {
  showDialogFlag: boolean;
  phoneNumber: string;
  recipient: RecipientConversationDto;
  closeDialog: () => void;
}

export const MonitorCampaignPopup: React.FC<MonitorCampaignProps> = ({
  showDialogFlag,
  closeDialog,
  phoneNumber,
  recipient,
}: MonitorCampaignProps) => {
  const { classes } = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={showDialogFlag}
      onClose={() => closeDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} variant="h6">
        {phoneNumber}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <MonitorConversationList
          phoneNumber={phoneNumber}
          recipient={recipient}
          closeDialog={closeDialog}
        />
      </DialogContent>
      <DialogActions>
        <IconButton
          className={classes.customizedButton}
          onClick={() => {
            closeDialog();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

import React, { useState } from 'react';
import {
  Typography,
  Stack,
  TextField,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@mui/material';
import { MonitorConversationList } from '@widgets/Popup/MonitorCampaign/MonitorConversationList';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

export const MonitorConversationPage = () => {
  const [conversationId, setConversationId] = useState('');
  const [submittedId, setSubmittedId] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [recentIds, setRecentIds] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConversationId(event.target.value);
  };

  const handleSubmit = (id?: string) => {
    const idToSubmit = id || conversationId;
    if (!idToSubmit) {
      setError(t('superAdminMainPage.monitorConversation.errors.conversationIdEmptyError'));
      return;
    }
    setSubmittedId(idToSubmit);
    setError(null); // Reset error when a new ID is submitted
    setRecentIds((prevIds) => [idToSubmit, ...prevIds]); // Add the new ID to the list of recent IDs
  };

  const handleReplay = (id: string) => {
    setSubmittedId(id);
    setConversationId(id);
    setError(null); // Reset error when a new ID is submitted
  };

  const handleRemoveRecentId = (idToRemove: string) => {
    setRecentIds((prevIds) => prevIds.filter((id) => id !== idToRemove));
  };

  const onError = (error: any) => {
    const errorMessage = error.data.conversationId
      ? error.data.conversationId.join(', ')
      : t('superAdminMainPage.monitorConversation.errors.errorOccurred');
    setError(errorMessage);
  };

  return (
    <div>
      <Stack spacing={2} direction="column" alignItems="center">
        <Typography variant="h4">
          {t('superAdminMainPage.monitorConversation.monitorConversation')}
        </Typography>
        {submittedId && (
          <Box display="flex" alignItems="center" mt={2}>
            <Typography variant="h6" color="primary">
              {t('superAdminMainPage.monitorConversation.activeConversationId')}
            </Typography>
            <Box ml={1} px={2} py={1} bgcolor="primary.main" color="white" borderRadius="8px">
              {submittedId}
            </Box>
          </Box>
        )}
        <TextField
          label={t('superAdminMainPage.monitorConversation.conversationIdLabel')}
          value={conversationId}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
          error={!!error}
          helperText={error}
        />
        <Button variant="contained" color="primary" onClick={() => handleSubmit()} fullWidth>
          {t('superAdminMainPage.monitorConversation.operation.getConversation')}
        </Button>
        {submittedId && !error && (
          <MonitorConversationList onError={onError} recipient={{ id: submittedId }} />
        )}
        {recentIds.length > 0 && (
          <Box mt={4} width="100%">
            <Typography variant="h6">
              {t('superAdminMainPage.monitorConversation.recentConversations')}
            </Typography>
            <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
              <List sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {recentIds.map((id, index) => (
                  <ListItem key={index} divider sx={{ width: '50%' }}>
                    <ListItemText primary={`Conversation ID: ${id}`} />
                    <IconButton onClick={() => handleReplay(id)} edge="end">
                      <ReplayIcon />
                    </IconButton>
                    <IconButton onClick={() => handleRemoveRecentId(id)} edge="end">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Button variant="outlined" color="secondary" onClick={() => setRecentIds([])} fullWidth>
              {t('superAdminMainPage.monitorConversation.clearRecentConversations')}
            </Button>
          </Box>
        )}
      </Stack>
    </div>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '@shared/layouts';
import { SignInForm } from '@features/Auth';
import { useSuperAdminSignIn } from '@features/Auth/SuperAdminSignIn/hooks/useSuperAdminSignIn';
import { Button } from '@mui/material';
import { UnauthenticatedPath } from '@shared/constants/routes.constants';
import { useStyles } from '../styles';

export const SuperAdminSignInPage: React.FC = () => {
  const { onSubmit, control, setValue, errorMsg, formState } = useSuperAdminSignIn();
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <AuthLayout>
      <div className={classes.root}>
        <Button onClick={() => navigate(UnauthenticatedPath.SIGN_IN)}>Sign in As Admin</Button>
        <SignInForm
          title="Sign In (as Root Admin)"
          onSubmit={onSubmit}
          control={control}
          setValue={setValue}
          errorMsg={errorMsg}
          formState={formState}
        />
      </div>
    </AuthLayout>
  );
};

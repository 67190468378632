import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { useGetAllAgentsControllerGetAllAgentsQuery } from '@shared/services/apiService/apiService';
import { AgentList } from './AgentList';
import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import Spinner from '@shared/components/spinner/Spinner';

import { AuthenticatedPath } from '@shared/constants/routes.constants';
import { useStyles } from './styles';
import { useAppSelector } from '@shared/store';

export const AgentPage: React.FC = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    data: agentList,
    isLoading,
    refetch,
  } = useGetAllAgentsControllerGetAllAgentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const currentCompany = useAppSelector((state) => state.auth.authenticatedUserCompany);

  const [loadingFlag, setLoadingFlag] = useState(false);

  const BCrumb = [
    {
      to: AuthenticatedPath.AGENT,
      title: t('agentPage.header.agent'),
    },
  ];

  const setLoading = async (flag: boolean) => {
    await refetch();
    setLoadingFlag(flag);
  };

  if (isLoading || loadingFlag) {
    return <Spinner />;
  }

  return (
    <>
      <PageContainer title={currentCompany?.title} description={currentCompany?.title}>
        <Breadcrumb
          title={t('adminMainPage.header.companyName', { companyName: currentCompany?.title })}
          items={BCrumb}>
          <></>
        </Breadcrumb>
        <Box className={classes.main}>
          <AgentList
            onRefresh={refetch}
            setLoading={setLoading}
            agentList={agentList ? agentList : []}
          />
        </Box>
      </PageContainer>
    </>
  );
};

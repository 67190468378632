import { LeadListSimpleDto, LeadSimpleDto } from '@shared/services/apiService/apiService';
import { ExtendedLeadList } from '@features/Admin/Campaign/model/type';

export const removeDuplicatePhones = (data: ExtendedLeadList[]): LeadListSimpleDto[] => {
  const globalUniquePhones = new Set<string>();

  return data.reduce<LeadListSimpleDto[]>((acc, item) => {
    const filteredLeads: LeadSimpleDto[] = item.leads.filter((lead: any) => {
      const phone = lead.phone;
      if (!globalUniquePhones.has(phone)) {
        globalUniquePhones.add(phone);
        return true;
      }
      return false;
    });

    if (filteredLeads.length > 0) {
      acc.push({
        name: item.name,
        leads: filteredLeads,
      });
    }

    return acc;
  }, []);
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  MenuItem,
  Typography,
  Grid,
  Divider,
  Button,
  Stack,
  CardContent,
  Tab,
  Box,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

import {
  IconArrowsSort,
  IconCalendarEvent,
  IconFlag3,
  IconHeadphones,
  IconRefresh,
  IconTrash,
} from '@tabler/icons-react';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

import {
  CallScheduleRequestDto,
  CampaignDto,
  CampaignRunRecipientStatusType,
  GetRecipientConversationControllerGetCampaignApiResponse,
  MedianCallDurationDto,
  RecipientConversationSentimentDto,
  useCreateCampaignRunControllerCreateCampaignRunMutation,
  useDeleteCampaignControllerDeleteCampaignMutation,
  useDeleteLeadListControllerDeleteLeadListMutation,
  useGetAllAgentsControllerGetAllAgentsQuery,
  useGetAllCampaignsControllerGetAllCampaignsQuery,
  useGetCampaignControllerGetCampaignQuery,
  useGetMedianCallDurationControllerGetCampaignQuery,
  useStopCampaignRunControllerStopCampaignRunMutation,
} from '@shared/services/apiService/apiService';
import Spinner from '@shared/components/spinner/Spinner';
import { MonitorCampaignPopup } from '@widgets/Popup/MonitorCampaign/MonitorCampaign';
import { SchedulePopup } from '@widgets/Popup/Schedule/Schedule';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import CustomFormLabel from '@shared/components/forms/theme-elements/CustomFormLabel';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import BlankCard from '@shared/components/shared/BlankCard';

import { useStyles } from './styles';
import { capitalize } from 'lodash';
import CampaignStatusButton, {
  CampaignStatus,
} from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton';
import { useEditCampaign } from '@features/Admin/Campaign/hooks/useEditCampaign';
import { CampaignFieldsError, FormFields } from '@shared/types/admin/campaign';
import { useGetRecipient } from '@features/Admin/Campaign/hooks/useGetRecipient';
import {
  formatInteractionResult,
  getCallDuration,
  getMedianCallDurationInMin,
  getSentimentsColor,
  getTotalSentimentsScore,
} from './utils';
import { ConfirmDeleteDialog } from '@shared/components/confirmDeleteDialog/ConfirmDeleteDialog';
import { CampaignTextField } from '@shared/components/campaignTextField/campaignTextField';
import CustomAlert from '@shared/components/alert/alert';
import { CampaignRetryFields } from '@shared/components/campaignRetryFields/CampaignRetryFields';
import { CampaignFileField } from '@shared/components/campaignFileField/CampaignFileField';
import { initialSchedule } from '@shared/constants/initialSchedule';
import { POLLING_INTERVAL_CONVERSATION } from '@shared/constants/polling-intervals';
import { ConfirmDialog } from '@shared/components/confirmDialog/ConfirmDialog';
import { CountDown } from '@shared/components/countDown/CountDown';
import { formatTime } from '@shared/utils/FormatTime';
import { getCampaignStatus } from '@pages/Admin/Main/Campaign/ui/CampaignStatusButton/utils';
import { getNextAvailableCallTimestamp, isOutOfSchedule } from '@shared/utils/DateUtil';
import { RecipientData, SortConfig } from './types';
import { useAppSelector } from '@shared/store';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const EditCampaignPage: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();

  const campaignDetailsTab = t('campaignPage.campaignDetails');
  const ongoingCallsTab = t('campaignPage.ongoingCalls');

  const [campaignFieldError, setCampaignFieldError] = useState<CampaignFieldsError>({
    retryIntervalInMinutes: [],
    retryMaxNumber: [],
    retriesPerDay: [],
    editErrorMessage: '',
  });

  const [campaignId, setCampaignId] = useState<string | null>();
  const [campaign, setCampaign] = useState<CampaignDto | null>();
  const [recipientId, setRecipientId] = useState<string>('');
  const [medianCallDuration, setMedianCallDuration] = useState<MedianCallDurationDto>();

  const [recipients, setRecipients] =
    useState<GetRecipientConversationControllerGetCampaignApiResponse | null>();

  const [leadListChanged, setLeadListChanged] = useState(false);

  const { recipientRefetch, pollingRecipientInterval, setPollingRecipientInterval } =
    useGetRecipient(campaignId as string);

  const [useRunCampaign, { isLoading: isRunningLoading }] =
    useCreateCampaignRunControllerCreateCampaignRunMutation();

  const [useStopCampaign, { isLoading: isStoppingLoading }] =
    useStopCampaignRunControllerStopCampaignRunMutation();

  const { onSubmit, setValue, control, reset, responseLoadingFlag } = useEditCampaign({
    setCampaignFieldError,
    leadListChanged,
    leadList: campaign?.leadList || [],
  });

  const {
    data: campaignData,
    refetch,
    isLoading,
  } = useGetCampaignControllerGetCampaignQuery(
    { id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  );

  const { refetch: refetchMedianCallDuration } = useGetMedianCallDurationControllerGetCampaignQuery(
    { id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  );

  const [useDeleteCampaign] = useDeleteCampaignControllerDeleteCampaignMutation();

  const [useDeleteLeadList] = useDeleteLeadListControllerDeleteLeadListMutation();

  const { refetch: refetchAllCampaigns } = useGetAllCampaignsControllerGetAllCampaignsQuery();

  const { data: agentList, error } = useGetAllAgentsControllerGetAllAgentsQuery();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setCampaignId(id);
    }
  }, [searchParams]);

  useEffect(() => {
    if (campaignId) {
      refetch().then((r) => {
        setCampaign(r.data);
      });

      recipientRefetch().then((r) => {
        setRecipients(r.data);
      });

      reloadMedianCallDuration();
    }
  }, [campaignId, refetch, recipientRefetch]);

  const BCrumb = [
    {
      to: AuthenticatedPath.CAMPAIGN,
      title: t('campaignPage.header.campaign'),
    },
    {
      title: t('action.edit') + ` ${campaign?.title}`,
    },
  ];

  const [tabIdx, setTabIdx] = React.useState(0);
  const [calls, setCalls] = useState<RecipientData[]>([]);
  const [showMonitorDialogFlag, setShowMonitorDialogFlag] = useState(false);
  const [showScheduleDilaogFlag, setShowScheduleDialogFlag] = useState(false);
  const [showScheduleWarningDilaogFlag, setShowScheduleWarningDialogFlag] = useState(false);
  const [timeToStart, setTimeToStart] = useState<number>(new Date().getTime());
  const [showCountDownFlag, setShowCountDownFlag] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const [schedule, setSchedule] = useState<CallScheduleRequestDto>(initialSchedule);
  const [excelFieldError, setExcelFieldError] = useState('');
  const [agent, setAgent] = useState('');
  const [agentName, setAgentName] = useState('');
  const [templateTextFieldValue, setTemplateTextFieldValue] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [campaignNameError, setCampaignNameError] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [retryMaxNumberValue, setRetryMaxNumberValue] = useState('');
  const [retriesPerDayValue, setRetriesPerDayValue] = useState('');
  const [retryIntervalInMinutesValue, setRetryIntervalInMinutesValue] = useState('');
  const [callsPhoneNumber, setCallsPhoneNumber] = useState('');
  const [tab, setTab] = useState(campaignDetailsTab);
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: null, direction: 'asc' });

  const currentCompany = useAppSelector((state) => state.auth.authenticatedUserCompany);

  useEffect(() => {
    if (tab === ongoingCallsTab) {
      const intervalId = setInterval(() => {
        recipientRefetch()
          .then((r) => {
            setRecipients(r.data);
            setPollingRecipientInterval(POLLING_INTERVAL_CONVERSATION);
          })
          .catch(() => {});
      }, pollingRecipientInterval);

      return () => clearInterval(intervalId);
    }
  }, [recipientRefetch, pollingRecipientInterval, tab]);

  useEffect(() => {
    if (recipients) {
      const mappedRecipients = recipients?.map(
        ({
          id,
          leadId,
          status,
          sentiments,
          answeredAt,
          interactionResult,
          terminatedAt,
          lead: { phone },
          startedAt,
        }) => ({
          id: leadId,
          mainId: id,
          sentiments: sentiments || [],
          status,
          interactionResult: interactionResult ? formatInteractionResult(interactionResult) : null,
          phoneNumber: phone,
          answeredAt: answeredAt,
          startDate: startedAt as string,
          callDuration:
            startedAt && answeredAt && terminatedAt
              ? getCallDuration(answeredAt, terminatedAt)
              : null,
        }),
      ) as RecipientData[];

      setCalls(mappedRecipients);
    }
  }, [recipients]);

  useEffect(() => {
    if (agentList && campaignData && campaignData.callSchedule) {
      setIsFile(true);

      const { leadList, ...campaignDataWithoutLeadSist } = campaignData;

      reset(campaignDataWithoutLeadSist);

      let currentAgent = agentList.find((agent) => agent.id == campaignData.agentId);
      if (currentAgent) {
        setAgent(currentAgent.id);
        setAgentName(currentAgent.mission.humanName);
      }

      setCampaignName(campaignData.title);
      setRetryMaxNumberValue(campaignData.retryMaxNumber.toString());
      setRetriesPerDayValue(campaignData.retriesPerDay.toString());
      setRetryIntervalInMinutesValue(campaignData.retryIntervalInMinutes.toString());
      setTemplateTextFieldValue(campaignData.followUpTemplate || '');
      setSchedule(campaignData.callSchedule);
    }
    if (error) {
      console.error('Error fetching agents:', error);
    }
  }, [agentList, error, campaignData, reset]);

  useEffect(() => {
    setValue('callSchedule', schedule);
  }, [schedule]);

  useEffect(() => {
    const campaignStatus = getCampaignStatus(campaignData?.campaignRun);
    const nextAvailableCallTimestamp = getNextAvailableCallTimestamp(campaignData?.callSchedule);

    if (nextAvailableCallTimestamp && CampaignStatus.NotStarted !== campaignStatus) {
      setTimeToStart(nextAvailableCallTimestamp);
      setShowCountDownFlag(true);
    }
    return () => {
      setShowCountDownFlag(false);
    };
  }, [campaignData]);

  const reloadMedianCallDuration = () => {
    refetchMedianCallDuration().then((r) => {
      setMedianCallDuration(r.data);
    });
  };

  const closeDialog = () => {
    setShowMonitorDialogFlag(false);
    setShowScheduleDialogFlag(false);
    setShowScheduleWarningDialogFlag(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIdx(newValue);
  };

  const handleSort = (key: keyof RecipientData, fixedDirection?: 'asc' | 'desc') => {
    let direction: 'asc' | 'desc' = fixedDirection || 'asc';
    if (!fixedDirection && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }

    setSortConfig({ key, direction });
  };

  const sortCalls = (calls: RecipientData[], sortConfig: SortConfig) => {
    if (!sortConfig.key) return calls;

    const sortedCalls = [...calls].sort((a, b) => {
      let aValue: number | string | RecipientConversationSentimentDto[] = a[sortConfig.key!] ?? '';
      let bValue: number | string | RecipientConversationSentimentDto[] = b[sortConfig.key!] ?? '';

      if (sortConfig.key === 'sentiments') {
        aValue = a.sentiments.length ? getTotalSentimentsScore(a.sentiments) : -Infinity;
        bValue = b.sentiments.length ? getTotalSentimentsScore(b.sentiments) : -Infinity;
      }

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return sortedCalls;
  };

  const handleSortPhoneNumber = () => handleSort('phoneNumber');
  const handleSortDate = () => handleSort('startDate');
  const handleSortDuration = () => handleSort('callDuration');
  const handleSortSentiments = (direction?: 'asc' | 'desc') => handleSort('sentiments', direction);

  const handleManagedStartStop = async (id: string, campaignStatus: CampaignStatus) => {
    if (CampaignStatus.NotStarted === campaignStatus) {
      const canRun = schedule && !isOutOfSchedule(schedule);
      if (canRun) {
        await useRunCampaign({ id });
      } else {
        const nextAvailableCallTimestamp = getNextAvailableCallTimestamp(schedule);
        if (nextAvailableCallTimestamp) {
          setTimeToStart(nextAvailableCallTimestamp);
          setShowScheduleWarningDialogFlag(true);
        }
      }
    } else if (CampaignStatus.Dialing === campaignStatus) {
      await useStopCampaign({ id });
      setShowCountDownFlag(false);
    }

    refetch();
  };

  const runCampaign = async () => {
    setShowCountDownFlag(true);
    closeDialog();
    if (campaign?.id) {
      await useRunCampaign({ id: campaign?.id });
    }

    refetch();
  };

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValue(name as FormFields, value);

    if (name == 'title') {
      setCampaignName(value);
    }
  };

  const handleAgentChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setAgent(value as string);
    let chosenAgent = agentList?.filter((currentAgent) => currentAgent.id == value);
    if (chosenAgent) {
      setAgentName(chosenAgent[0].mission.humanName);
    }
    setValue(name as FormFields, value);
  };

  const handleDeleteBtn = (id: any) => {
    setShowDialog(true);
    setDeleteId(id);
  };

  const onLeadListChanged = () => setLeadListChanged(true);

  const deleteCampaign = async () => {
    if (deleteId) {
      setShowDialog(false);
      setLoadingFlag(true);
      const response = await useDeleteCampaign({
        id: deleteId,
      });
      if ('error' in response) {
        throw response.error;
      } else {
        await refetchAllCampaigns();
        navigator('/');
      }
    }
    setDeleteId('');
    setLoadingFlag(false);
  };

  const deleteLeadList = async (leadListId: string) => {
    if (leadListId) {
      setLoadingFlag(true);
      const response = await useDeleteLeadList({
        id: leadListId,
      });
      if ('error' in response) {
        throw response.error;
      }

      await refetch();
    }
    setLoadingFlag(false);
  };

  const handleSubmit = () => {
    setCampaignFieldError({});

    if (
      !campaignName ||
      !retryMaxNumberValue ||
      !retriesPerDayValue ||
      !retryIntervalInMinutesValue
    ) {
      if (!campaignName) {
        setCampaignNameError('Add campaign name');
      }

      if (!retryMaxNumberValue) {
        setCampaignFieldError((prevState) => ({
          ...prevState,
          retryMaxNumber: ['Add overall retry attempts'],
        }));
      }

      if (!retriesPerDayValue) {
        setCampaignFieldError((prevState) => ({
          ...prevState,
          retriesPerDay: ['Add retries per day'],
        }));
      }

      if (!retryIntervalInMinutesValue) {
        setCampaignFieldError((prevState) => ({
          ...prevState,
          retryIntervalInMinutes: ['Add retry delay'],
        }));
      }
    } else {
      onSubmit();
    }
  };

  const transformToDDMMYYYY = (timestamp: string) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
      .format(date)
      .replace(/\//g, '.');
  };

  let mastecheValues = { agentName, templateTextFieldValue, campaignName };

  const getStatusColor = (
    status: CampaignRunRecipientStatusType,
  ): 'success' | 'error' | 'default' | 'primary' | 'secondary' | 'info' | 'warning' => {
    if (status === 'pending') return 'default';
    if (status === 'failed') return 'error';
    if (status === 'calling') return 'warning';
    if (status === 'processing') return 'info';
    if (status === 'completed') return 'success';

    return 'default';
  };

  let fields = [
    {
      name: 'retryMaxNumber',
      label: t('campaignPage.overallRetryAttempt'),
      placeholder: t('campaignPage.overallRetryAttemptPlaceholder'),
      retryFieldError: campaignFieldError.retryMaxNumber || [],
      retryFieldValue: retryMaxNumberValue,
      setRetryFieldValue: setRetryMaxNumberValue,
    },
    {
      name: 'retriesPerDay',
      label: t('campaignPage.retryPerDay'),
      placeholder: t('campaignPage.retryPerDayPlaceholder'),
      retryFieldError: campaignFieldError.retriesPerDay || [],
      retryFieldValue: retriesPerDayValue,
      setRetryFieldValue: setRetriesPerDayValue,
    },
    {
      name: 'retryIntervalInMinutes',
      label: t('campaignPage.retryDelay'),
      placeholder: t('campaignPage.retryDelayPlaceholder'),
      retryFieldError: campaignFieldError.retryIntervalInMinutes || [],
      retryFieldValue: retryIntervalInMinutesValue,
      setRetryFieldValue: setRetryIntervalInMinutesValue,
    },
  ];

  const handleMonitorClick = (rowId: string, phone: string) => {
    setShowMonitorDialogFlag(true);
    setRecipientId(rowId);
    setCallsPhoneNumber(phone);
  };

  const handleOnTabClick = (tab: string) => () => setTab(tab);

  const isCampaignRunning = ['active', 'finishing'].includes(
    campaignData?.campaignRun?.status as string,
  );

  const currentRecipient = recipients?.find((recipient) => recipient.id === recipientId);

  if (loadingFlag || responseLoadingFlag || isLoading || !campaign) {
    return <Spinner />;
  }

  const sortedCalls = sortCalls(calls, sortConfig);

  return (
    <>
      <PageContainer>
        <Breadcrumb
          title={t('campaignPage.header.campaignName', { campaignName: currentCompany?.title })}
          items={BCrumb}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BlankCard>
              <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
                <Tabs
                  value={tabIdx}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example">
                  <Tab
                    iconPosition="start"
                    icon={<CampaignOutlinedIcon />}
                    label={t('campaignPage.campaignDetails')}
                    {...a11yProps(0)}
                    onClick={handleOnTabClick(t('campaignPage.campaignDetails'))}
                  />
                  <Tab
                    iconPosition="start"
                    icon={<LocalPhoneOutlinedIcon />}
                    label={t('campaignPage.ongoingCalls')}
                    {...a11yProps(1)}
                    onClick={handleOnTabClick(t('campaignPage.ongoingCalls'))}
                  />
                </Tabs>
              </Box>
              <Divider />
              <CardContent>
                <TabPanel value={tabIdx} index={0}>
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6}>
                        <CardContent>
                          <CustomFormLabel
                            sx={{
                              mt: 0,
                            }}
                            htmlFor="campaign-name">
                            {t('campaignPage.campaignName')}:
                          </CustomFormLabel>
                          <CustomTextField
                            control={control}
                            name="title"
                            onChange={handleOnChangeInput}
                            value={campaignName}
                            id="campaign-title"
                            variant="outlined"
                            fullWidth
                            required
                            defaultValue={campaign?.title}
                          />
                          {!campaignName && (
                            <Typography component="p" sx={{ color: '#FF0000', width: '300px' }}>
                              {campaignNameError}
                            </Typography>
                          )}

                          <CustomFormLabel htmlFor="campaign-agent">
                            {t('campaignPage.selectAgent')}:
                          </CustomFormLabel>
                          <FormControl fullWidth variant="outlined">
                            {/* <InputLabel
                            id="campaign-agent"
                            sx={{
                              top: '-5px',
                              '&.MuiInputLabel-shrink': {
                                top: '0px', 
                              },
                            }}>
                            Agent
                          </InputLabel> */}
                            <CustomSelect
                              control={control}
                              name="agentId"
                              labelId="campaign-agent"
                              onChange={handleAgentChange}
                              id="campaign-agent-id"
                              value={agent}>
                              {agentList &&
                                agentList.map((agent, index) => (
                                  <MenuItem key={index} value={agent.id}>
                                    {agent.name}
                                  </MenuItem>
                                ))}
                            </CustomSelect>
                          </FormControl>

                          <CampaignFileField
                            setIsFile={setIsFile}
                            campaignFieldError={campaignFieldError}
                            setCampaignFieldError={setCampaignFieldError}
                            setValue={setValue}
                            setExcelFieldError={setExcelFieldError}
                            excelFieldError={excelFieldError}
                            onLeadListChanged={onLeadListChanged}
                            onDeleteFile={deleteLeadList}
                            isEditable={!isCampaignRunning}
                            leadList={campaignData ? campaignData.leadList : undefined}
                          />
                        </CardContent>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <CardContent>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              color: '#7C8FAC',
                              marginTop: '0px',
                              marginBottom: '20px',
                            }}>
                            {t('campaignPage.callSetting')}
                          </Typography>
                          <Button
                            onClick={() => setShowScheduleDialogFlag(true)}
                            startIcon={<IconCalendarEvent />}
                            variant="contained">
                            {t('campaignPage.changeSchedule')}
                          </Button>

                          <CampaignRetryFields
                            fields={fields}
                            setCampaignFieldError={setCampaignFieldError}
                            setValue={setValue}
                            control={control}
                          />
                        </CardContent>
                      </Grid>
                    </Grid>
                    <CampaignTextField
                      mastecheValues={mastecheValues}
                      setTemplateTextFieldValue={setTemplateTextFieldValue}
                      setValue={setValue}
                      control={control}
                    />
                    <CardContent>
                      <Stack direction="column">
                        <Stack
                          sx={{ marginTop: '30px' }}
                          direction="row"
                          justifyContent="space-between">
                          <Stack direction="row" spacing="20px">
                            <CampaignStatusButton
                              onClick={handleManagedStartStop}
                              disabled={
                                isRunningLoading ||
                                isStoppingLoading ||
                                !campaignData?.leadList?.length
                              }
                              campaign={campaignData}
                              statusLabels={{
                                startText: t('adminMainPage.operation.startCampaign'),
                                stopText: t('adminMainPage.operation.stopCampaign'),
                              }}
                            />
                            <Button
                              onClick={() => {
                                handleDeleteBtn(campaignId);
                              }}
                              variant="outlined"
                              color="error"
                              startIcon={<IconTrash size={24} />}
                              disabled={isCampaignRunning}>
                              {t('action.delete')}
                            </Button>
                          </Stack>
                          <Stack direction="row" justifyContent="space-around" spacing="20px">
                            <Button
                              onClick={handleSubmit}
                              variant="contained"
                              color="primary"
                              disabled={isCampaignRunning}>
                              {t('action.save')}
                            </Button>
                            <Button onClick={() => navigator(AuthenticatedPath.CAMPAIGN)}>
                              Cancel
                            </Button>
                          </Stack>
                        </Stack>
                        <Box sx={{ ml: 5 }}>
                          {showCountDownFlag && timeToStart > new Date().getTime() ? (
                            <CountDown endTimestamp={new Date(timeToStart)} />
                          ) : (
                            ''
                          )}
                        </Box>
                      </Stack>
                    </CardContent>
                  </form>
                </TabPanel>

                <TabPanel value={tabIdx} index={1}>
                  {sortedCalls.length ? (
                    <TableContainer>
                      <Table>
                        <TableHead className={classes.tableheahder}>
                          <TableRow>
                            <TableCell
                              onClick={handleSortPhoneNumber}
                              className={classes.table_first_cell}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.phoneNumber')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell onClick={handleSortDate}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.startDate')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.status')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell onClick={handleSortDuration}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.callDuration')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>

                            <TableCell onClick={() => handleSortSentiments()}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.sentimentIndi')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>
                            <TableCell onClick={() => handleSortSentiments()}>
                              <Box className={classes.table_sort_cell}>
                                <Typography variant="h6" className={classes.table_th}>
                                  {t('campaignPage.interactionResult')}
                                </Typography>
                                <IconArrowsSort size="18" />
                              </Box>
                            </TableCell>

                            <TableCell className={classes.table_last_cell}>
                              <Typography variant="h6" className={classes.table_th} align="left">
                                {t('campaignPage.action')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedCalls.map((row) => {
                            return (
                              <TableRow className={classes.table_row} key={row.id} hover>
                                <TableCell align="left" className={classes.table_first_cell}>
                                  <Typography variant="subtitle1">{row.phoneNumber}</Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {!row.startDate ? (
                                    <Chip label={t('campaignPage.unavailable')} />
                                  ) : (
                                    <Typography variant="subtitle1">
                                      {transformToDDMMYYYY(row.startDate) || row.status}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell align="left">
                                  <Chip
                                    label={capitalize(row.status)}
                                    color={getStatusColor(row.status)}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Stack direction="row" alignItems="center" spacing="10px">
                                    {!row.callDuration && !row.startDate ? (
                                      <>
                                        <Chip label={t('campaignPage.unavailable')} />
                                      </>
                                    ) : (
                                      <Typography variant="subtitle1">
                                        {row.callDuration ? (
                                          <>{row.callDuration} </>
                                        ) : (
                                          <Chip label={t('campaignPage.unavailable')} />
                                        )}
                                      </Typography>
                                    )}
                                  </Stack>
                                </TableCell>
                                <TableCell align="center">
                                  {!row.sentiments.length ? (
                                    <Chip
                                      style={{ position: 'relative', right: 45 }}
                                      label={t('campaignPage.unavailable')}
                                    />
                                  ) : (
                                    <Box marginRight={10} display={'flex'} justifyContent="center">
                                      <IconFlag3 color={getSentimentsColor(row.sentiments)} />
                                    </Box>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <Typography marginRight={4}>
                                    {row.interactionResult ? (
                                      <Typography style={{ position: 'relative', right: 35 }}>
                                        {row.interactionResult}
                                      </Typography>
                                    ) : (
                                      <Chip
                                        style={{ position: 'relative', right: 20 }}
                                        label={t('campaignPage.unavailable')}
                                      />
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ paddingRight: 0 }}>
                                  {(!['processing', 'pending'].includes(row.status) ||
                                    row.interactionResult) && (
                                    <Button
                                      onClick={() =>
                                        handleMonitorClick(row.mainId, row.phoneNumber)
                                      }
                                      variant="outlined"
                                      startIcon={<IconHeadphones />}>
                                      {t('action.monitor')}
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Typography sx={{ textAlign: 'center' }}>
                      {t('campaignPage.emptyOngoingCalls')}
                    </Typography>
                  )}
                  <Stack marginTop="20px" direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing="10px">
                      <Box width="170px">
                        <Typography fontSize="14px" color="#5A6A85">
                          {t('campaignPage.averageDuration')}
                        </Typography>
                        <Typography fontSize="16px" color="#2A3547">
                          <span>
                            {getMedianCallDurationInMin(medianCallDuration?.medianCallDuration)}
                          </span>
                        </Typography>
                      </Box>
                      <Button
                        onClick={reloadMedianCallDuration}
                        variant="outlined"
                        startIcon={<IconRefresh />}>
                        {t('action.reset')}
                      </Button>
                    </Stack>
                    {/*<Button variant="outlined">Close</Button>*/}
                  </Stack>
                </TabPanel>
              </CardContent>
            </BlankCard>
          </Grid>
        </Grid>
        <>
          {showMonitorDialogFlag && currentRecipient && (
            <MonitorCampaignPopup
              showDialogFlag={showMonitorDialogFlag}
              closeDialog={closeDialog}
              phoneNumber={callsPhoneNumber}
              recipient={currentRecipient}
            />
          )}
        </>
        <SchedulePopup
          showDialogFlag={showScheduleDilaogFlag}
          closeDialog={closeDialog}
          schedule={schedule}
          setSchedule={setSchedule}
        />

        <ConfirmDeleteDialog
          open={showDialog}
          onClose={setShowDialog}
          dialogTitle={'dialog.title3'}
          dialogContentText={'dialog.content3'}
          showDialog={setShowDialog}
          confirmDelete={deleteCampaign}
        />

        <ConfirmDialog
          showDialogFlag={showScheduleWarningDilaogFlag}
          handleClose={closeDialog}
          dialogTitle={'dialog.title4'}
          dialogContentText={t('dialog.content4', {
            timeToStart: formatTime((timeToStart - new Date().getTime()) / 1000, false).slice(0, 5),
          })}
          showDialog={setShowScheduleWarningDialogFlag}
          confirmDialog={runCampaign}
        />
      </PageContainer>
      {campaignFieldError.editErrorMessage ? (
        <CustomAlert message={campaignFieldError.editErrorMessage} type={'error'} />
      ) : (
        ''
      )}
    </>
  );
};

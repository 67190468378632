import '@livekit/components-styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { debounce } from 'lodash';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import { useMediaDeviceSelect } from '@livekit/components-react';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import PhonePausedOutlinedIcon from '@mui/icons-material/PhonePausedOutlined';

import CustomFormLabel from '@shared/components/forms/theme-elements/CustomFormLabel';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';

import { useStyles } from './styles';
import {
  ConversationInfoDto,
  RecipientConversationDto,
  useGetOutboundTestCallToPhoneInfoControllerGetInfoQuery,
  useHangupTestCallOnPhoneControllerUserMeMutation,
  useHangupTestCallOnWebControllerHangupMutation,
  useOutboundTestCallToPhoneControllerOutboundCallMutation,
  useOutboundTestCallToWebControllerUserMeMutation,
} from '@shared/services/apiService/apiService';

import CustomSlider from '@shared/components/forms/theme-elements/CustomSlider';

import { createLocalAudioTrack, LogLevel, Room, setLogLevel } from 'livekit-client';
import { REACT_APP_LIVEKIT_HOST } from '@shared/constants/environments';
import { LivekitRoomConnector } from '@features/Livekit';
import { useAlert } from '@shared/hooks/useAlert';
import { AlertMessage } from '@shared/components/alert/alertMessage';
import { ErrorData, TestingAgentProps } from '@shared/types/admin/agent';
import { formatErrorMessage } from './formatError';
import { MonitorConversationList } from '../MonitorCampaign/MonitorConversationList';

export const TestingAgentLivekitPopup: React.FC<TestingAgentProps> = ({
  showDialogFlag,
  agentId,
  closeDialog,
}: TestingAgentProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { message, severity, setAlert, clearAlert } = useAlert();

  const [testType, setTestType] = useState(0);
  const [volume, setVolume] = useState(30);
  const [phoneNumber, setPhoneNumber] = useState<string | null>('');
  const [startCallTime, setStartCallTime] = useState<Date | null>(null);
  const [outboundCallIsLoading, setOutboundCallIsLoading] = useState<boolean>(false);
  const [conversationId, setConversationId] = useState<string | null>(null);

  const [outboundTestCallToWeb, { isLoading: outboundWebIsLoading }] =
    useOutboundTestCallToWebControllerUserMeMutation();
  const [hangupTestCallOnWeb, { isLoading: hangUpWebIsLoading }] =
    useHangupTestCallOnWebControllerHangupMutation();
  const [outboundTestCallToPhone, { isLoading: outboundPhoneIsLoading }] =
    useOutboundTestCallToPhoneControllerOutboundCallMutation();
  const [hangupTestCallToPhone, { isLoading: hangUpPhoneIsLoading }] =
    useHangupTestCallOnPhoneControllerUserMeMutation();
  const { refetch: outboundCallInfoRetech } =
    useGetOutboundTestCallToPhoneInfoControllerGetInfoQuery();

  const [room, setRoom] = useState<Room | null>(null);
  const [token, setToken] = useState<string | null>(null);

  const { setActiveMediaDevice, activeDeviceId, devices } = useMediaDeviceSelect({
    kind: 'audioinput',
    requestPermissions: showDialogFlag,
  });

  useEffect(() => {
    const callApi = async () => {
      if (showDialogFlag && startCallTime && phoneNumber) {
        const response = await outboundCallInfoRetech();

        if (response.error) {
          setStartCallTime(null);
          setAlert('Call Ended. You can make another call now.', 'error');
        }
      }
    };
    callApi();
  }, [showDialogFlag]);

  useEffect(() => {
    if (devices.length > 0 && !activeDeviceId) {
      setActiveMediaDevice(devices[0].deviceId);
    }
  }, [devices, activeDeviceId, setActiveMediaDevice]);

  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearAlert();
    setStartCallTime(null);
    setTestType(Number(event.target.value));
  };

  const handleOnChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const handleChangeVolumeSlider = (_event: Event, newValue: number | number[]) => {
    if (typeof newValue !== 'number') return;

    setVolume(newValue);
  };

  const connectToRoom = async (token: string) => {
    const newRoom = new Room({});

    setLogLevel(LogLevel.silent);
    const serverUrl = REACT_APP_LIVEKIT_HOST;

    try {
      await newRoom.connect(serverUrl, token);
      setRoom(newRoom);
      setToken(token);

      const track = await createLocalAudioTrack({
        deviceId: activeDeviceId,
      });
      newRoom.localParticipant.publishTrack(track);
    } catch (err) {
      console.error(err);

      setRoom(null);
      setToken(null);
    }
  };

  const handleOutboundCallToWeb = async () => {
    const response = await outboundTestCallToWeb({
      outboundTestCallToWebV2RequestDto: { agentId },
    }).unwrap();

    setConversationId(response.callInfo.metadata.conversationId);

    const token = response.roomAccessToken;
    setOutboundCallIsLoading(true);
    await connectToRoom(token);
    setOutboundCallIsLoading(false);

    setStartCallTime(new Date(+response.callInfo.creationTime * 1000));
  };

  const handleOutboundCallToPhone = async () => {
    if (!phoneNumber) {
      setAlert('Required phone number.', 'error');
      return;
    }

    const response = await outboundTestCallToPhone({
      outboundTestCallToPhoneRequestDto: {
        agentId,
        calleePhone: phoneNumber,
      },
    });

    if ('error' in response) {
      const { error } = response;
      if (error) {
        handleOutCallToPhoneErrRes(error);
      }
    } else if ('data' in response) {
      setConversationId(response.data.metadata.conversationId);
      handleOutCallToPhoneSuccessRes(response.data);
    }
  };

  function handleOutCallToPhoneErrRes(error: FetchBaseQueryError | SerializedError) {
    if ('data' in error && error.data) {
      const errorData = error.data as ErrorData;
      setAlert(errorData.message, 'error');
      return;
    }
    setAlert('An unknown error occurred.', 'error');
  }

  function handleOutCallToPhoneSuccessRes(data: ConversationInfoDto) {
    setStartCallTime(new Date(+data.creationTime * 1000));
  }

  const handleOutboundCall = async () => {
    if (outboundPhoneIsLoading || outboundWebIsLoading || outboundCallIsLoading) {
      return;
    }
    const callHandlers: { [key: number]: () => Promise<void> } = {
      0: handleOutboundCallToWeb,
      1: handleOutboundCallToPhone,
    };

    const handler = callHandlers?.[testType];

    if (!handler) {
      return;
    }

    try {
      await handler();
    } catch (error: any) {
      if (error?.data) {
        setAlert(formatErrorMessage(error?.data), 'error');
      } else {
        setAlert(t('adminMainPage.campaigns.somethingWentWrong'), 'error');
      }
    }
  };

  const handleHangupCallOnWeb = async () => {
    console.log('room', room);
    if (room) {
      await hangupTestCallOnWeb().unwrap();
      setToken(null);
      setRoom(null);
    }
  };

  const handleHangupCallOnPhone = async () => {
    await hangupTestCallToPhone().unwrap();
    setAlert('Call ended. You can make another call now.', 'success');
    setStartCallTime(null);
  };

  const handleHangupCall = async () => {
    if (hangUpPhoneIsLoading || hangUpWebIsLoading) {
      return;
    }

    const callHandlers: { [key: number]: () => Promise<void> } = {
      0: handleHangupCallOnWeb,
      1: handleHangupCallOnPhone,
    };

    const handler = callHandlers?.[testType];

    if (!handler) {
      return;
    }

    try {
      await handler();
      setConversationId(null);
    } catch (error) {
      const customError = error as { data: { message: string } };

      if (customError?.data?.message) {
        setAlert(customError.data.message, 'error');
      } else {
        setAlert(t('adminMainPage.campaigns.somethingWentWrong'), 'error');
      }
    }
  };

  const handleHangupCallDebounce = debounce(handleHangupCall, 500);

  const handleDeviceChange = async (deviceId: string) => {
    setActiveMediaDevice(deviceId);

    if (room) {
      await room.switchActiveDevice('audioinput', deviceId);
    }
  };

  const isRoomActive = !!room;

  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false);

  const handleConfirmCloseDialogOpen = () => {
    setConfirmCloseDialogOpen(true);
  };

  const handleConfirmCloseDialogClose = () => {
    setConfirmCloseDialogOpen(false);
  };

  const handleConfirmClose = async () => {
    await handleHangupCall();
    setConfirmCloseDialogOpen(false);
    closeDialog();
  };

  const onCloseDialogHandler = async () => {
    if (isRoomActive || startCallTime) {
      handleConfirmCloseDialogOpen();
    } else {
      await handleHangupCall();
      closeDialog();
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isRoomActive || startCallTime) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isRoomActive, startCallTime]);

  const ConfirmCloseDialog = () => (
    <Dialog
      open={confirmCloseDialogOpen}
      onClose={handleConfirmCloseDialogClose}
      aria-labelledby="confirm-close-dialog-title"
      aria-describedby="confirm-close-dialog-description">
      <DialogTitle id="confirm-close-dialog-title">
        {t('agentPage.dialog.confirmCloseTitle')}
      </DialogTitle>
      <DialogContent>{t('agentPage.dialog.confirmCloseMessage')}</DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmCloseDialogClose} color="primary">
          {t('agentPage.dialog.cancel')}
        </Button>
        <Button onClick={handleConfirmClose} color="error" autoFocus>
          {t('agentPage.dialog.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Dialog
      className={classes.dialog}
      open={showDialogFlag}
      onClose={onCloseDialogHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle} variant="h6">
        {t('agentPage.dialog.titleLivekit')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <ConfirmCloseDialog />
        <Grid container>
          <Grid item xs={12}>
            <CustomFormLabel htmlFor="testing-type" sx={{ mt: 0 }}>
              Type:
            </CustomFormLabel>
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              id="testing-type"
              variant="outlined"
              value={testType}
              onChange={handleChangeType}
              fullWidth
              required>
              <MenuItem value={0}>{t('agentPage.dialog.viaBroswer')}</MenuItem>
              <MenuItem value={1}>{t('agentPage.dialog.viaMobile')}</MenuItem>
            </CustomSelect>
          </Grid>

          {testType == 1 ? (
            <>
              <Grid item xs={12} mt="20px">
                <CustomFormLabel htmlFor="testing-phone-number" sx={{ mt: 0 }}>
                  Phone Number:
                </CustomFormLabel>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  value={phoneNumber}
                  onChange={handleOnChangePhoneNumber}
                  id="testing-phone-number"
                  type="text"
                  varient="outlined"
                  inputProps={{ maxLength: '256' }}
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} mt="20px">
                <CustomFormLabel htmlFor="testing-phone-number" sx={{ mt: 0 }}>
                  Setting:
                </CustomFormLabel>
              </Grid>

              <LivekitRoomConnector volume={volume} token={token} room={room}>
                {devices.length > 0 && (
                  <CustomSelect
                    id="testing-type"
                    variant="outlined"
                    value={activeDeviceId}
                    onChange={(data: SelectChangeEvent<string>) =>
                      handleDeviceChange(data.target.value)
                    }
                    fullWidth
                    required>
                    {devices.map((device) => (
                      <MenuItem key={device.deviceId} value={device.deviceId}>
                        {device.label || `Device ${device.deviceId}`}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </LivekitRoomConnector>

              <Grid item xs={12} mt="15px" mb="15px">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton
                    edge="start"
                    onClick={() => (volume === 0 ? setVolume(30) : setVolume(0))}>
                    {volume == 0 ? <VolumeOffOutlinedIcon /> : <VolumeUpOutlinedIcon />}
                  </IconButton>
                  <CustomSlider
                    aria-label="Volume"
                    value={volume}
                    onChange={handleChangeVolumeSlider}
                  />
                </Stack>
              </Grid>
            </>
          )}

          {message && (
            <AlertMessage message={message} severity={severity} clearAlert={clearAlert} />
          )}
          <Grid item xs={12} mt="20px">
            <Stack direction="row" justifyContent="center" spacing="20px">
              <Button
                disabled={testType == 0 ? !!token : !!startCallTime}
                onClick={handleOutboundCall}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={
                  outboundWebIsLoading || outboundPhoneIsLoading || outboundCallIsLoading ? (
                    <CircularProgress color="secondary" size={20} />
                  ) : (
                    <PhoneInTalkOutlinedIcon />
                  )
                }
                fullWidth>
                {t('agentPage.dialog.makeCall')}
              </Button>
              <Button
                disabled={testType == 0 ? !token : !startCallTime}
                onClick={handleHangupCallDebounce}
                startIcon={
                  hangUpPhoneIsLoading || hangUpWebIsLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <PhonePausedOutlinedIcon />
                  )
                }
                variant="text"
                color="error"
                fullWidth>
                {t('agentPage.dialog.hangUp')}
              </Button>
            </Stack>
          </Grid>

          {conversationId && (
            <MonitorConversationList
              startCallTime={startCallTime || undefined}
              recipient={
                { id: conversationId } as Omit<Partial<RecipientConversationDto>, 'id'> & {
                  id: string;
                }
              }
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <IconButton
          className={classes.customizedButton}
          onClick={() => {
            onCloseDialogHandler();
          }}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { FunctionComponent, SVGProps } from 'react';

export type CompanyType = {
  title: string;
  logo: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
};

export type AuthStateType = {
  accessToken: string | null;
  superAdminAccessToken: string | null;
  isLoading: boolean;
  authenticatedUserCompany?: CompanyType;
};

const storedToken = localStorage.getItem('accessToken');
const superAdminStoredToken = localStorage.getItem('superAdminAccessToken');

const initialState: AuthStateType = {
  authenticatedUserCompany: undefined,
  accessToken: storedToken ? storedToken : null,
  isLoading: false,
  superAdminAccessToken: superAdminStoredToken ? superAdminStoredToken : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.accessToken = action.payload;
      state.authenticatedUserCompany = undefined;
      localStorage.setItem('accessToken', action.payload);
    },
    signInSuperAdmin: (state, action) => {
      state.superAdminAccessToken = action.payload;
      state.authenticatedUserCompany = undefined;
      localStorage.setItem('superAdminAccessToken', action.payload);
    },
    signOutSuperAdmin: (state) => {
      state.superAdminAccessToken = null;
      state.authenticatedUserCompany = undefined;
      localStorage.removeItem('superAdminAccessToken');
      return state;
    },
    signOut: (state) => {
      state.accessToken = null;
      state.authenticatedUserCompany = undefined;
      localStorage.removeItem('accessToken');
      return state;
    },
    setAuthenticatedUserCompany: (state, action) => {
      state.authenticatedUserCompany = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const actions = {
  ...authSlice.actions,
};

export const { reducer } = authSlice;

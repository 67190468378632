import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import '@shared/utils/i18n';
import App from './app';
import { store } from '@shared/store';
import Spinner from '@shared/components/spinner/Spinner';

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: process.env.SENTRY_ENV,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'https://web.dev.callevate.ai',
    'https://web.stg.callevate.ai',
    'https://callevate.ai',
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Suspense>
    </Provider>
  </Sentry.ErrorBoundary>,
);

import { Button, Chip, Grid, LinearProgress, Snackbar, Tooltip, Typography } from '@mui/material';
import { IconFile, IconPlus } from '@tabler/icons-react';
import { t } from 'i18next';
import { ReactSortable } from 'react-sortablejs';
import CustomFormLabel from '../forms/theme-elements/CustomFormLabel';
import readXlsxFile from 'read-excel-file';
import { useEffect, useRef, useState } from 'react';
import CustomAlert from '../alert/alert';
import { ConfirmDeleteDialog } from '../confirmDeleteDialog/ConfirmDeleteDialog';
import { UseFormSetValue } from 'react-hook-form';
import { CreateCampaignFormValue } from '@features/Admin/Campaign/model/type';
import { CampaignLeadListDto } from '@shared/services/apiService/apiService';
import { useStyles } from '@pages/Admin/Campaign/ui/styles';
import * as XLSX from 'xlsx';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

type Column = {
  [key: string]: any;
};

export type FileData = {
  id: number;
  fileName: string;
  columns: Column;
  uuid?: string;
};

interface CampaignFileField {
  setIsFile: React.Dispatch<React.SetStateAction<boolean>>;
  campaignFieldError: Partial<{
    leadErrors: string | null;
    retryIntervalInMinutes?: string[];
    retryMaxNumber?: string[];
    retriesPerDay?: string[];
    editErrorMessage?: string;
  }>;
  setCampaignFieldError: React.Dispatch<
    React.SetStateAction<
      Partial<{
        leadErrors: string | null;
        retryIntervalInMinutes?: string[];
        retryMaxNumber?: string[];
        retriesPerDay?: string[];
        editErrorMessage?: string;
      }>
    >
  >;
  setValue: UseFormSetValue<CreateCampaignFormValue>;
  excelFieldError: string;
  setExcelFieldError: React.Dispatch<React.SetStateAction<string>>;
  leadList?: CampaignLeadListDto[];
  onLeadListChanged?: () => void;
  onDeleteFile?: (leadListId: string) => Promise<void>;
  isEditable?: boolean;
}

export const CampaignFileField = ({
  setIsFile,
  campaignFieldError,
  setCampaignFieldError,
  setValue,
  leadList,
  excelFieldError,
  setExcelFieldError,
  onLeadListChanged,
  onDeleteFile,
  isEditable,
}: CampaignFileField) => {
  const { classes } = useStyles();

  let filesNameStorage: { [key: string]: string } = {};

  const [fileUploadingProgress, setFileUploadingProgress] = useState(0);
  const [fileLimitError, setFileLimitError] = useState(''); // more like restriction with notification
  const [listDeleteDialog, setListDeleteDialog] = useState(false);
  const [selectedListIdx, setListIdx] = useState<number | null>(null);
  const [snackBarShow, setSnackBarShow] = useState(false);
  const [countOfPhoneNumbers, setCountOfPhoneNumbers] = useState(0);
  const [extractedCountOfPhoneNumbers, setExtractedCountOfPhoneNumbers] = useState(0);

  const [fileDataArray, setFileDataArray] = useState<FileData[]>([]);
  const [fileToDelete, setFileToDelete] = useState<FileData | null>(null);
  const [inputFilesNameStorage, setInputFilesNameStorage] = useState<{ [key: string]: string }>(
    filesNameStorage,
  );

  useEffect(() => {
    if (leadList) {
      const modifiedLeadList = leadList.map(({ id, name, leads }) => ({
        id,
        name,
        leads: leads.map(({ phone }) => ({ phone })),
      }));

      for (let i = 0; i < modifiedLeadList.length; i++) {
        if (!inputFilesNameStorage.hasOwnProperty(modifiedLeadList[i].name)) {
          inputFilesNameStorage[modifiedLeadList[i].name] = modifiedLeadList[i].name;
        }
      }

      const newFileDataArray = modifiedLeadList.map((item, index) => ({
        id: index,
        uuid: item.id,
        fileName: item.name,
        columns: {
          phone: item.leads.map((lead) => lead.phone),
        },
      }));

      setFileDataArray(newFileDataArray);

      setValue('leadList', modifiedLeadList);
    }
  }, [leadList]);

  const inputFile = useRef<HTMLInputElement>(null);

  const handleAddFileData = async () => {
    await setFileLimitError('');
    if (Object.keys(inputFilesNameStorage).length == 5) {
      setFileLimitError(t('campaignPage.filesLimit'));
      return;
    }
    setCampaignFieldError({});
    setExcelFieldError('');
    inputFile?.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileUploadingProgress(10);
    const file = e.target.files && e.target.files[0];

    let fileName = file?.name;
    if (fileName) {
      if (fileName in inputFilesNameStorage) {
        setFileUploadingProgress(0);
        return;
      } else {
        setInputFilesNameStorage((prevState) => ({ ...prevState, [fileName!]: fileName! }));
      }
    }

    if (!file) {
      return;
    }

    setIsFile(true);

    setFileUploadingProgress(80);

    readXlsxFile(file).then((rows: Column) => {
      if (rows[0][0] !== 'phone') {
        setCampaignFieldError((prevState) => ({
          ...prevState,
          leadErrors: t('campaignPage.fileColumnNameValidation'),
        }));
        setFileUploadingProgress(0);
        return;
      }
      const columnNames = rows[0] as string[];
      let columns: Column = {};

      setFileUploadingProgress(60);

      columnNames.forEach((name) => {
        columns[name] = [];
      });

      for (let i = 1; i < rows.length; i++) {
        let row = rows[i];
        for (let j = 0; j < row.length; j++) {
          let cell = row[j];
          let columnName = columnNames[j];

          const phoneNumber = String(cell || '').trim();
          if (columnName && columnName.toLowerCase() === 'phone' && phoneNumber) {
            const parsedPhoneNumber = parsePhoneNumberFromString(
              phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`,
            );
            columns[columnName].push(parsedPhoneNumber?.format('E.164'));

            setCountOfPhoneNumbers(columns[columnName].length);
          }
        }
      }

      const uniquePhoneNumbers = [...new Set(columns.phone)];

      const fileData: FileData = {
        id: fileDataArray.length,
        fileName: file.name,
        columns,
      };
      setExtractedCountOfPhoneNumbers(uniquePhoneNumbers.length);

      setFileUploadingProgress(100);
      setFileDataArray((prev) => [...prev, fileData]);
      onLeadListChanged?.();

      setTimeout(() => {
        setFileUploadingProgress(0);
      }, 500);

      setSnackBarShow(true);
    });

    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };

  const onDragDropEnds = (oldIndex: number | undefined, newIndex: number | undefined) => {
    if (typeof oldIndex === 'number' && typeof newIndex === 'number') {
      const newArray = [...fileDataArray];
      const [removedItem] = newArray.splice(oldIndex, 1);
      newArray.splice(newIndex, 0, removedItem);
      const updatedArray = newArray.map((item, index) => ({ ...item, id: index }));
      setFileDataArray(updatedArray);
    }
  };

  const handleDeleteList = (fileData: FileData) => {
    const { id } = fileData;
    setListDeleteDialog(true);
    setListIdx(id);
    setFileToDelete(fileData);
    setFileLimitError('');
  };

  const deleteFileData = async () => {
    const fileNameToDelete = fileToDelete?.fileName;
    for (let key in inputFilesNameStorage) {
      if (inputFilesNameStorage[key] == fileNameToDelete) {
        setInputFilesNameStorage((prevState) => {
          const newState = { ...prevState };
          delete newState[fileNameToDelete];
          return newState;
        });
        setFileToDelete(null);
      }
    }

    if (selectedListIdx === null || !fileToDelete) {
      return;
    }

    if (fileToDelete.uuid && onDeleteFile) {
      // delete from db
      await onDeleteFile(fileToDelete.uuid);
    }

    const updatedList = fileDataArray.filter((item) => item.id !== selectedListIdx);
    const updatedIDsList = updatedList.map((item) => {
      return item.id > selectedListIdx ? { ...item, id: item.id - 1 } : item;
    });
    setFileDataArray(updatedIDsList);
    setListDeleteDialog(false);

    if (!fileDataArray) {
      setListIdx(null);
    }

    onLeadListChanged?.();
  };

  const downloadExcel = (fileName: string, data: number[]) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(
      data.map((phone) => ({ phone })),
      { header: ['phone'] },
    );
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName);
  };

  const handleDownload = (fileName: string, data: number[]) => {
    downloadExcel(fileName, data);
  };

  useEffect(() => {
    const newNumbersLists = fileDataArray.map((fileData) => ({
      id: fileData.uuid,
      name: fileData.fileName,
      leads: fileData.columns.phone.map((phone: { toString: () => any }) => ({
        phone: phone.toString(),
      })),
    }));

    if (newNumbersLists.length === 0) {
      setIsFile(false);
    }

    setValue('leadList', newNumbersLists);
  }, [fileDataArray]);

  return (
    <>
      <CustomFormLabel htmlFor="campaign-agent">{t('campaignPage.numberManage')}:</CustomFormLabel>

      {fileUploadingProgress != 0 ? (
        <>
          <LinearProgress variant="determinate" value={fileUploadingProgress} />
        </>
      ) : (
        ''
      )}

      <ReactSortable
        list={fileDataArray}
        setList={(newlist) => setFileDataArray(newlist)}
        ghostClass={classes.dropArea}
        handle=".dragHandle"
        filter=".ignoreDrag"
        preventOnFilter={true}
        className={classes.gridContainer}
        onEnd={({ oldIndex, newIndex }) => onDragDropEnds(oldIndex, newIndex)}>
        <>
          {fileDataArray &&
            fileDataArray.map((row) => {
              return (
                <Grid className="dragHandle" key={row.id} item>
                  <Tooltip title={row.fileName}>
                    <Chip
                      sx={{
                        color: '#7C8FAC',
                        width: '112px',
                      }}
                      icon={
                        <IconFile
                          color="#7C8FAC"
                          style={{
                            fontSize: '24px',
                          }}
                        />
                      }
                      label={
                        <div
                          style={{
                            width: '46px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}>
                          {row.fileName}
                        </div>
                      }
                      variant="outlined"
                      onClick={() => handleDownload(row.fileName, row.columns.phone)}
                      onDelete={isEditable ? () => handleDeleteList(row) : undefined}
                    />
                  </Tooltip>
                </Grid>
              );
            })}

          {isEditable && (
            <Grid item>
              <input
                ref={inputFile}
                type="file"
                style={{ display: 'none' }}
                accept=".xlsx,.xls"
                onChange={handleFileChange}
              />
              <Button
                onClick={() => handleAddFileData().then(() => setExcelFieldError(''))}
                startIcon={<IconPlus />}
                sx={{ marginBottom: '10px', borderRadius: '100px' }}>
                {t('campaignPage.addList')}
              </Button>
            </Grid>
          )}
        </>
      </ReactSortable>

      {campaignFieldError.leadErrors && (
        <Typography component="p" sx={{ color: '#FF0000', width: '300px' }}>
          {campaignFieldError.leadErrors}
        </Typography>
      )}

      {excelFieldError && (
        <Typography component="p" sx={{ color: '#FF0000', whiteSpace: 'pre-wrap' }}>
          {excelFieldError}
        </Typography>
      )}

      <Snackbar
        open={snackBarShow}
        autoHideDuration={2000}
        onClose={() => setSnackBarShow(false)}
        message={`${extractedCountOfPhoneNumbers} out of ${countOfPhoneNumbers} numbers were extracted`}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />

      <ConfirmDeleteDialog
        open={listDeleteDialog}
        onClose={setListDeleteDialog}
        dialogTitle={'dialog.title1'}
        dialogContentText={'dialog.content'}
        showDialog={setListDeleteDialog}
        confirmDelete={deleteFileData}
      />
      {fileLimitError ? <CustomAlert message={fileLimitError} type={'error'} /> : ''}
    </>
  );
};

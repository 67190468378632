import { POLLING_INTERVAL_CONVERSATION } from '@shared/constants/polling-intervals';
import { useGetRecipientConversationControllerGetCampaignQuery } from '@shared/services/apiService/apiService';
import { useState } from 'react';

export const useGetRecipient = (campaignId: string) => {
  const [pollingRecipientInterval, setPollingRecipientInterval] = useState(
    POLLING_INTERVAL_CONVERSATION,
  );

  const {
    data: recipientData,
    refetch: recipientRefetch,
    isLoading: recipientIsLoading,
    isError: isErrorRecipient,
  } = useGetRecipientConversationControllerGetCampaignQuery(
    { id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true },
  );

  return {
    recipientData,
    isErrorRecipient,
    recipientRefetch,
    recipientIsLoading,
    pollingRecipientInterval,
    setPollingRecipientInterval,
  };
};

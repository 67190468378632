import { useState, useEffect } from 'react';

const useTimer = (startTimestamp: Date | null) => {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    if (startTimestamp != null) {
      const startTime = startTimestamp.getTime();
      const updateDuration = () => {
        const currentTime = Date.now();
        setDuration(Math.floor((currentTime - startTime) / 1000));
      };
      updateDuration();
      const timerId = setInterval(updateDuration, 1000);
      return () => clearInterval(timerId);
    }
  }, [startTimestamp]);

  return duration;
};

export default useTimer;

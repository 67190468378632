import { useState, useEffect } from 'react';

const msInSecond = 1000;

const useCountDown = (endTimestamp: Date | null) => {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    if (endTimestamp != null) {
      const endTime = endTimestamp.getTime();
      const updateDuration = () => {
        const currentTime = Date.now();
        setDuration(Math.floor((endTime - currentTime) / msInSecond));
      };
      updateDuration();
      const timerId = setInterval(updateDuration, msInSecond);
      return () => clearInterval(timerId);
    }
  }, [endTimestamp]);

  return duration;
};

export default useCountDown;

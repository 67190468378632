import { uniqueId } from 'lodash';
import { IconAperture, IconMailAi, IconActivityHeartbeat, IconPackage } from '@tabler/icons-react';
import i18n from '@shared/utils/i18n';

interface MenuitemsType {
  [x: string]: any;
  id?: string;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: string;
  children?: MenuitemsType[];
  chip?: string;
  chipColor?: string;
  variant?: string;
  external?: boolean;
}

export const AdminMenuItems: MenuitemsType[] = [
  {
    id: uniqueId(),
    title: i18n.t('adminMainPage.menuItems.campaignList'),
    icon: IconAperture,
    href: '/admin/campaign',
  },
  {
    id: uniqueId(),
    title: i18n.t('adminMainPage.menuItems.agentList'),
    icon: IconPackage,
    href: '/admin/agent',
  },
];

export const SuperAdminMenuItems: MenuitemsType[] = [
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.companies'),
    icon: IconAperture,
    href: '/super-admin/companies',
  },
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.monitorConversation'),
    icon: IconMailAi,
    href: '/super-admin/conversation/monitor',
  },
  {
    id: uniqueId(),
    title: i18n.t('superAdminMainPage.menuItems.tokensMonitoring'),
    icon: IconActivityHeartbeat,
    href: '/super-admin/tokens-monitoring',
  },
];

export default AdminMenuItems;

import { CallScheduleDto } from '@shared/services/apiService/apiService';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isoWeek);
dayjs.locale('en');

const DEFAULT_TIMEZONE = 'Asia/Dubai';
const dayMap = {
  0: { start: 'sundayStart', end: 'sundayEnd' },
  1: { start: 'mondayStart', end: 'mondayEnd' },
  2: { start: 'tuesdayStart', end: 'tuesdayEnd' },
  3: { start: 'wednesdayStart', end: 'wednesdayEnd' },
  4: { start: 'thursdayStart', end: 'thursdayEnd' },
  5: { start: 'fridayStart', end: 'fridayEnd' },
  6: { start: 'saturdayStart', end: 'saturdayEnd' },
};

const add = (
  date: string | number | dayjs.Dayjs | Date,
  { value, unit }: { value: number; unit: dayjs.ManipulateType },
) => {
  return dayjs(date).add(value, unit);
};

export const currentTimestamp = () => {
  return dayjs().millisecond(0);
};

const parseWithZone = (
  tz: string,
  param?: string | number | dayjs.Dayjs | Date,
  keepLocalTime = false,
) => {
  return dayjs(param).tz(tz, keepLocalTime);
};

const getTodayCallSchedule = (currentDay: number, schedule: CallScheduleDto) => {
  const { start, end } = dayMap[currentDay as keyof typeof dayMap];
  const startValue = schedule[start as keyof CallScheduleDto];
  const endValue = schedule[end as keyof CallScheduleDto];
  return {
    start: startValue,
    end: endValue,
  };
};

const getDayOfWeekIndex = (date: dayjs.Dayjs): number => {
  return date.day();
};

export const isOutOfSchedule = (schedule: CallScheduleDto, now: Date = new Date()): boolean => {
  const timezone = schedule.timezone || DEFAULT_TIMEZONE;
  const nowInZone = dayjs(now).tz(timezone);
  const dayIndex = getDayOfWeekIndex(nowInZone);
  const { start, end } = getTodayCallSchedule(dayIndex, schedule);

  if (!start || !end) {
    return true;
  }

  const currentTime = nowInZone.format('HH:mm:ss');

  return currentTime < start || currentTime > end;
};

export const getNextAvailableCallTimestamp = (
  schedule: CallScheduleDto | undefined,
): number | null => {
  if (!schedule) {
    return null;
  }

  const timezone = schedule.timezone || DEFAULT_TIMEZONE;
  const now = parseWithZone(timezone, currentTimestamp());
  const currentDayOfWeek = getDayOfWeekIndex(now);

  for (let i = 0; i < Object.keys(dayMap).length; i++) {
    const dayIndex = (currentDayOfWeek + i) % 7;
    const { start } = getTodayCallSchedule(dayIndex, schedule);

    if (start) {
      let targetDate = add(now, { value: i, unit: 'day' });
      const [hours, minutes, seconds] = start.split(':').map(Number);
      targetDate = targetDate.hour(hours).minute(minutes).second(seconds).millisecond(0);
      const startTimestamp = targetDate.valueOf();
      if (startTimestamp < now.valueOf()) {
        continue;
      }
      return startTimestamp;
    }
  }

  return null;
};

import React from 'react';
import { Room } from 'livekit-client';
import { LiveKitRoom } from '@livekit/components-react';
import { REACT_APP_LIVEKIT_HOST } from '@shared/constants/environments';
import { ActiveRoom } from './ActiveRoom';
import { Box, Grid } from '@mui/material';

interface LivekitRoomConnectorProps {
  room: Room | null;
  token: string | null;
  volume: number;
  children: React.ReactNode;
}

export const LivekitRoomConnector: React.FC<LivekitRoomConnectorProps> = ({
  room,
  token,
  volume,
  children,
}) => {
  let LiveKitRoomComponent = LiveKitRoom;

  return (
    <Grid container alignItems="center">
      {room && token && (
        <Grid item>
          <Box display="flex" alignItems="center" style={{ marginRight: 8 }}>
            <LiveKitRoomComponent room={room} serverUrl={REACT_APP_LIVEKIT_HOST} token={token}>
              <ActiveRoom volume={volume} />
            </LiveKitRoomComponent>
          </Box>
        </Grid>
      )}
      <Grid item xs>
        {children}
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { Typography } from '@mui/material';
import { formatTime } from '@shared/utils/FormatTime';
import useCountDown from '@shared/hooks/useCountDown';

interface TimerProps {
  endTimestamp: Date | null;
}

export const CountDown: React.FC<TimerProps> = ({ endTimestamp }) => {
  const duration = useCountDown(endTimestamp);

  return (
    <Typography color="#7C8FAC" fontWeight="600" fontSize="14px">
      {formatTime(duration, false).slice(0, 5)}
    </Typography>
  );
};

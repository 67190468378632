import i18n from '@shared/utils/i18n';

const translate = (key: string) => {
  return i18n.t(`superAdminMainPage.companies.${key}`);
};

export const companyColumns = [
  { id: 'name', label: translate('tableHeaders.name') },
  { id: 'industryType', label: translate('tableHeaders.industryType') },
  { id: 'description', label: translate('tableHeaders.description') },
  { id: 'address', label: translate('tableHeaders.address') },
  { id: 'createdAt', label: translate('tableHeaders.createdAt'), isDate: true },
  { id: 'updatedAt', label: translate('tableHeaders.updatedAt'), isDate: true },
  { id: 'deletedAt', label: translate('tableHeaders.deletedAt'), isDate: true, isOptional: true },
];

export const agentColumns = [
  { id: 'name', label: translate('agentTableHeaders.agentName') },
  { id: 'model', label: translate('agentTableHeaders.model') },
  { id: 'voiceId', label: translate('agentTableHeaders.voiceId'), isTruncated: true },
  { id: 'missionId', label: translate('agentTableHeaders.missionId'), isTruncated: true },
  {
    id: 'langfusePromptId',
    label: translate('agentTableHeaders.langfusePromptId'),
    isTruncated: true,
  },
  { id: 'companyId', label: translate('agentTableHeaders.companyId'), isTruncated: true },
  { id: 'createdAt', label: translate('agentTableHeaders.createdAt'), isDate: true },
  { id: 'updatedAt', label: translate('agentTableHeaders.updatedAt'), isDate: true },
  {
    id: 'deletedAt',
    label: translate('agentTableHeaders.deletedAt'),
    isDate: true,
    isOptional: true,
  },
];

export const userColumns = [
  { id: 'firstName', label: translate('userTableHeaders.name'), isFullName: true },
  { id: 'email', label: translate('userTableHeaders.email') },
  { id: 'createdAt', label: translate('userTableHeaders.createdAt'), isDate: true },
  { id: 'updatedAt', label: translate('userTableHeaders.updatedAt'), isDate: true },
  {
    id: 'deletedAt',
    label: translate('userTableHeaders.deletedAt'),
    isDate: true,
    isOptional: true,
  },
];

import React from 'react';
import { Button, Stack } from '@mui/material';
import { useStyles } from '../styles';
import { useTranslation } from 'react-i18next';

interface ActionButtonsProps {
  closeDialog: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ closeDialog }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2} className={classes.actionButtons}>
      <Button type="submit" variant="contained" color="primary">
        {t('superAdminMainPage.companies.operation.save')}
      </Button>
      <Button onClick={closeDialog} variant="outlined">
        {t('superAdminMainPage.companies.operation.cancel')}
      </Button>
    </Stack>
  );
};

export default ActionButtons;

import { useLocation } from 'react-router-dom';

import { Box, useMediaQuery, Button } from '@mui/material';

import { RootState, useAppSelector } from '@shared/store';
import { useSignOut } from '@features/Auth/SignOut/hooks/useSignOut';
import { useSignOutSuperAdmin } from '@features/Auth/SignOut/hooks/useSignOutSuperAdmin';

export const Profile = () => {
  const customizer = useAppSelector((state: RootState) => state.customizer);
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up('lg'));
  const hideMenu = lgUp ? customizer.isCollapse && !customizer.isSidebarHover : '';

  const { pathname } = useLocation();
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf('/'));
  const isSuperAdminPage = pathWithoutLastPart.includes('super-admin');

  const onAdminLogout = useSignOut();
  const onSuperAdminLogout = useSignOutSuperAdmin();

  const onLogout = isSuperAdminPage ? onSuperAdminLogout : onAdminLogout;

  return (
    <Box display={'flex'} justifyContent="center" alignItems="center" gap={2} sx={{ m: 3, p: 2 }}>
      {!hideMenu ? (
        <>
          <Box mt={2} width="100%">
            <Button variant="outlined" color="primary" onClick={onLogout} fullWidth>
              Logout
            </Button>
          </Box>
        </>
      ) : (
        ''
      )}
    </Box>
  );
};
